import React from 'react';
import Sticky from 'react-stickynode';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from 'react-scroll-up';
import Navbar from './navbar/navbar';
import Newsletter from './newsletter/newsletter';
import Footer from './footer/footer';
import ScrollUpButton from './scroll-up-button/scroll-up-button';
import InstagramShowcase from '../containers/instagram-showcase';
import ResetCss from './reset-css';
import { theme } from '../theme';

type LayoutProps = {
  children: React.ReactNode;
};

/*
allGhostSettings {
    edges {
      node {
        codeinjection_styles
      }
    }
  }
*/
const Layout: React.FunctionComponent<LayoutProps> = ({ children }: LayoutProps) => {
  const { allGhostSettings } = useStaticQuery(
    graphql`
      query GhostSettings {
        allGhostSettings {
          edges {
            node {
              codeinjection_styles
            }
          }
        }
      }
   `
  ); 
  return (
    <ThemeProvider theme={theme}>
      <>
        <ResetCss />
        { 
        allGhostSettings.edges.length > 0 ? 
          (allGhostSettings.edges.map(
            ({ node }: any, i: number) => 
            (<style key={`style-${i}`} dangerouslySetInnerHTML={{ __html: node.codeinjection_styles }} />) )) 
        : ''}
        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <Navbar />
        </Sticky>

        {children}

        <InstagramShowcase />
        <Newsletter />
        <Footer>
          Copyright &copy; {new Date().getFullYear()} Yutine Fung
        </Footer>
        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
      </>
    </ThemeProvider>
  );
};

export default Layout;
